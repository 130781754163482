import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "./product.scss";
import GallerySwiper from "./gallary-swiper";
import { SketchPicker } from "react-color";
import ShippingInformation from "../shipping-information/home";
import { FiUpload } from "react-icons/fi";
import axiosInstance from "../helper";

const ProductPage = () => {
  let [togalColor, setTogalColor] = useState(false);
  let [selectedColor, setSelectedColor] = useState("");
  const [showModal, setShowModal] = useState(false);
  let [selectedProduct, setSelectedProduct] = useState({});
  const [shippingInfo, setShippingInfo] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    companyName: "",
    deliveryNote: "",
  });
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  // let imagepath = products[0].image;

  const location = useLocation();
  const myData = location.state;
  console.log(myData);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    async function handlefilterFetchdata() {
      let filter_product = await axiosInstance.get(
        `/product/product/${myData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(filter_product.data);
      setSelectedProduct(filter_product.data);
    }
    handlefilterFetchdata();
  }, [myData.id]);

  const handleSubmitShipping = async () => {
    const productDetails = {
      productId: selectedProduct.id,
      productName: selectedProduct.name,
      selectedColor,
    };

    const requestData = {
      ...shippingInfo,
      ...productDetails,
    };

    try {
      const response = await axiosInstance.post(
        "/submit-shipping",
        requestData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Shipping Information Submitted:", response.data);
      setShowModal(false); // Close modal after successful submission
    } catch (error) {
      console.error("Error submitting shipping info", error);
    }
  };
  return (
    <div className="product-container">
      <main className="product-page-part1">
        <div>
          <h1>Our exclusive Products</h1>
        </div>
        <div>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic,
            officia quos incidunt, <br />
            vero id quidem libero rerum dolore quod, nesciunt consequatur
          </p>
        </div>
      </main>
      <section className="product-page-main">
        <aside className="product-page-img">
          <div>
            <img
              src={`https://hash-stitch.onrender.com${myData.first_image}`}
              alt={`${myData.name}`}
            />
          </div>
          <div>
            <GallerySwiper images={selectedProduct.images} />
          </div>
        </aside>
        <aside className="product-page-content">
          <h1>{selectedProduct.name}</h1>
          <h6
            style={{
              fontSize: "18px",
            }}>
            $ {selectedProduct.price}
          </h6>
          <div className="product-page-content-part1">
            <p>{selectedProduct.description}</p>
            <p>
              -Lorem ipsum dolor sit amet, consectetur adipisicing elit. At,
              natus.
            </p>
            <p>
              -Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Accusantium, in!
            </p>
          </div>
          <div className="product-page-content-part2">
            <h4>Colour/Hue</h4>
            <button onClick={() => setTogalColor(true)}>
              Choose your hue here{" "}
              {togalColor ? (
                <SketchPicker
                  onChangeComplete={(e) => {
                    setSelectedColor(e.hex);
                    setTogalColor(false);
                  }}
                />
              ) : null}
              {togalColor ? null : (
                <div
                  style={{
                    background: selectedColor,
                    color: selectedColor == "" ? "white" : selectedColor,
                    width: "20px",
                    height: "20px",
                    borderRadius: "100px",
                    marginLeft: "50px",
                  }}>
                  a
                </div>
              )}
            </button>
            <div className="product-page-content-form">
              <div>
                <label htmlFor="ss">
                  GSM Vaiants <span>Add your own GSM</span>
                </label>
                <select id="ss">
                  <option value="value">value</option>
                  <option value="value">value</option>
                  <option value="value">value</option>
                  <option value="value">value</option>
                </select>
              </div>
              <div>
                <label htmlFor="bb">
                  Please input your requirement for GSM
                </label>
                <textarea name="bb" id=""></textarea>
              </div>
              <div>
                <label htmlFor="aa">Fabric</label>
                <select id="aa">
                  <option value="value">value</option>
                  <option value="value">value</option>
                  <option value="value">value</option>
                  <option value="value">value</option>
                </select>
              </div>
              <div>
                <label htmlFor="aa">Print Details</label>
                <input type="text" placeholder="type here ..." />
              </div>
              <div>
                <label htmlFor="">Upload Your Tech Stack</label>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  // onChange={handleFileChange}
                />
                <button onClick={handleButtonClick}>
                  {" "}
                  <FiUpload />
                  Upload
                </button>
              </div>
            </div>
            <button className="order-product" onClick={openModal}>
              Order the Product
            </button>
            <ShippingInformation
              show={showModal}
              onClose={closeModal}
              setShippingInfo={setShippingInfo}
              onSubmitShipping={handleSubmitShipping}
            />
          </div>
        </aside>
      </section>
    </div>
  );
};

export default ProductPage;
