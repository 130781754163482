// import React from "react";
// import "./aboutus4.scss";
// import tshirt from "../../assets/pexels-woven-swetter.jpg";
// import men from "../../assets/men.png";
// import garments from "../../assets/garments.png";
// import sports from "../../assets/women.png";
// import shirt from "../../assets/shirt.png";
// const Aboutus4 = () => {
//   return (
//     <div className="abouts4-container">
//       <div className="text-section">
//         <h1 className="title">
//           Stitch Sustainable <i>and Profitable</i>
//         </h1>
//         <h4
//           style={{
//             lineHeight: "normal",
//             letterSpacing: "5px",
//             color: "orange",
//           }}>
//           OUR VALUES
//         </h4>
//         <div className="text-sub-section">
//           <div className="text">
//             As we do not own the factories that make your products, we work with
//             carefully selected suppliers, many of which have worked with us for
//             many years. We work hard to ensure that your products are made with
//             respect for the environment, in good working conditions, where
//             workers’ rights are respected.
//           </div>
//         </div>
//       </div>

//       <div className="grid-container">
//         <div className="gallery">
//           <figure className="gallery__item gallery__item--1">
//             <img src={tshirt} alt="" className="gallery__img" />
//           </figure>
//           <figure className="gallery__item gallery__item--2 grid-text">
//             <h1>VISION</h1>
//           </figure>
//           <figure className="gallery__item gallery__item--3">
//             <img src={men} alt="" className="gallery__img" />
//           </figure>
//           <figure className="gallery__item gallery__item--4 grid-text">
//             <h1>Value</h1>
//           </figure>
//           <figure className="gallery__item gallery__item--5">
//             <img src={garments} alt="" className="gallery__img" />
//           </figure>
//           <figure className="gallery__item gallery__item--6 grid-text">
//             <h1>MISSION</h1>
//           </figure>
//           <figure className="gallery__item gallery__item--7">
//             <img src={sports} alt="" className="gallery__img" />
//           </figure>
//           <figure className="gallery__item gallery__item--8 grid-text">
//             <h1>FEED - BACK</h1>
//           </figure>
//           <figure className="gallery__item gallery__item--9">
//             <img src={shirt} alt="" className="gallery__img" />
//           </figure>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Aboutus4;

import React from "react";
import "./aboutus4.scss";
import tshirt from "../../assets/pexels-woven-swetter.jpg";
import men from "../../assets/men.png";
import garments from "../../assets/garments.png";
import sports from "../../assets/women.png";
import shirt from "../../assets/shirt.png";
const Aboutus4 = () => {
  return (
    <div className="abouts4-container">
      <div className="text-section">
        <h1 className="title">
          Stitch Sustainable <i>and Profitable</i>
        </h1>
        <h4
          style={{
            lineHeight: "normal",
            color: "#C19A6F",
            fontFamily: "Viaoda Libre",
            fontSize:"46px"
          }}>
          OUR VALUES
        </h4>
        <div className="text-sub-section">
          <div className="text">
            As we do not own the factories that make your products, we work with
            carefully selected suppliers, many of which have worked with us for
            many years. We work hard to ensure that your products are made with
            respect for the environment, in good working conditions, where
            workers’ rights are respected.
          </div>
        </div>
      </div>

      <div className="grid-container">
        <div className="gallery">
          <aside className="gallery-first">
            <div className="img-gallery">
              <img src={tshirt} alt="" />
            </div>
            <div className="gallery-text first-text">
              <h1>vision</h1>
            </div>
            <div className="img-gallery ">
              <img src={men} alt="" />
            </div>
          </aside>
          <aside className="gallery-second">
            <div className="gallery-text first-text">
              <h1>value</h1>
            </div>
            <div className="img-gallery mid-img">
              <img src={garments} alt="" />
            </div>
            <div className="gallery-text second-text">
              <h1>mission</h1>
            </div>
          </aside>
          <aside className="gallery-third">
            {" "}
            <div className="img-gallery">
              <img src={sports} alt="" />
            </div>
            <div className="gallery-text second-text">
              <h1>feed-back</h1>
            </div>
            <div className="img-gallery">
              <img src={shirt} alt="" />
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default Aboutus4;