import React from "react";
import "./shipping-information.scss";
import { MdClear } from "react-icons/md";
const ShippingInformation = ({
  show,
  onClose,
  shippingInfo,
  setShippingInfo,
  onSubmitShipping,
}) => {
  if (!show) {
    return null;
  }

  let inputdata = [
    {
      label: "Full Name",
      input_type: "text",
      placeholder: "user name",
    },
    {
      label: "Email",
      input_type: "email",
      placeholder: "email",
    },
    {
      label: "Conatact Number",
      input_type: "number",
      placeholder: "number",
    },
    {
      label: "Company Name",
      input_type: "text",
      placeholder: "company name",
    },
  ];
  const handleChange = (e) => {
    setShippingInfo({ ...shippingInfo, [e.target.name]: e.target.value });
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-body">
          <div className="modal-body-head">
            <div>
              <h1>Shipping Information</h1>
              <p>we ship within working days</p>
            </div>

            <button className="modal-close" onClick={onClose}>
              <MdClear />
            </button>
          </div>

          <aside className="modal-body-form">
            <form
              className="contact-form"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitShipping();
              }}>
              {inputdata.map((input) => (
                <div className="form-group" key={input.name}>
                  <label>{input.label}</label>
                  <input
                    type={input.input_type}
                    name={input.name}
                    placeholder={input.placeholder}
                    // value={shippingInfo[input.name] || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              ))}
              <div className="form-group">
                <label htmlFor="dn">Delivery note</label>
                <textarea name="" id="dn" value={""} onChange={handleChange}>
                  {" "}
                </textarea>
              </div>
              <button type="submit" className="sub-button">
                Submit Shipping Information
              </button>
            </form>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default ShippingInformation;
