import React from "react";
import "./product.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const GallerySwiper = ({ images }) => {
  return (
    <div className="product-gallery-container">
      <Swiper
        spaceBetween={images?.length }
        slidesPerView={images?.length -2}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}>
        {images?.map((d) => {
          return (
            <>
              <SwiperSlide>
                <div className="clothing-item">
                  <img
                    src={`https://hash-stitch.onrender.com${d.image}`}
                    alt="image"
                    className="clothing-image"
                  />
                </div>
              </SwiperSlide>
            </>
          );
        })}
      </Swiper>
    </div>
  );
};

export default GallerySwiper;
